export const switchHideShowWizardBlock = () => {
  const filtersSection = $(".filters");
  const wizardBtn = $(".wizard-btn");
  const goalFilter = $(".goal-filter");
  const wizardCLose = $(".wizard-close");
  // let isWizardVisible = localStorage.getItem("isWizardVisible") === "true";

  function wizardHandle(e) {
    e.preventDefault();
    // Инвертируем текущее состояние
    // isWizardVisible = !isWizardVisible;

    wizardBtn.toggleClass("active");
    filtersSection.find(".wizard").slideToggle(300);
    goalFilter.slideToggle(300);

    $(".wizardActiveInput").each(function () {
      $(this).attr("value", 1);
    });

    // if (isWizardVisible) {
    //   wizardBtn.addClass("active");
    //   filtersSection.find(".wizard").slideDown(300);
    //   goalFilter.slideUp(300);

    //   $(".wizardActiveInput").each(function () {
    //     $(this).attr("value", 1);
    //   });
    // } else {
    //   wizardBtn.removeClass("active");
    //   filtersSection.find(".wizard").slideUp(300);
    //   goalFilter.slideDown(300);

    //   $(".wizardActiveInput").each(function () {
    //     $(this).attr("value", 0);
    //   });
    // }

    // меняем значение
    // localStorage.setItem("isWizardVisible", isWizardVisible);

    // disable select
    if (wizardBtn.hasClass("active")) {
      wizardBtn
        .parent()
        .find(".custom-select")
        .not('[name="orderby"]')
        .prop("disabled", true);
    } else {
      wizardBtn.parent().find(".custom-select").prop("disabled", false);
    }
  }

  wizardBtn.on("click", wizardHandle);

  wizardCLose.on("click", wizardHandle);
};
