export function enableSetSLider() {
  $(".set-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    adaptiveHeight: true,
    infinite: false,
    arrows: true,
    prevArrow:
      '<div class="set-prev"><i class="far fa-chevron-left"></i></div>',
    nextArrow:
      '<div class="set-next"><i class="far fa-chevron-right"></i></div>',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: "unslick",
      },
    ],
  });
}
